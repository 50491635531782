import React from "react";
import { Clock, ArrowUpRight } from "react-feather";
import Image from "@ui/image";
import { Link } from "gatsby";

const BlogCard = ({ title, categories, image, readingTime, slug }) => {
    const to = `/article/${slug}`;
    return (
        <>
            <div className="rn-blog" role="button" tabIndex="-1">
                <div className="inner">
                    <div className="thumbnail">
                        <Link to={to}>
                            <Image src={image} alt={title} />
                        </Link>
                    </div>
                    <div className="content">
                        <div className="category-info">
                            <div className="category-list">
                                <Link to={to}>{categories[0]}</Link>
                            </div>
                            <div className="meta">
                                {readingTime && (
                                    <span>
                                        <Clock size={13} /> {readingTime}
                                    </span>
                                )}
                            </div>
                        </div>
                        <h4 className="title">
                            <Link to={to}>
                                {title}
                                <ArrowUpRight />
                            </Link>
                        </h4>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BlogCard;
