import React from "react";
import SectionTitle from "@components/section-title";
import BlogCard from "./blog-card";

const BlogArea = ({ data, id }) => {
    return (
        <div className="rn-blog-area rn-section-gap section-separator" id={id}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        {data?.section_title && (
                            <SectionTitle
                                {...data.section_title}
                                align="center"
                                data-aos="fade-up"
                                data-aos-duration="500"
                                data-aos-delay="500"
                                data-aos-once="true"
                            />
                        )}
                    </div>
                </div>
                <div className="row row--25 mt--30 mt_md--10 mt_sm--10">
                    {data?.blogs?.map(({ node }) => (
                        <div
                            key={node.fields.slug}
                            data-aos="fade-up"
                            data-aos-duration="500"
                            data-aos-delay="400"
                            data-aos-once="true"
                            className="col-lg-6 col-xl-4 mt--30 col-md-6 col-sm-12 col-12 mt--30"
                        >
                            <BlogCard
                                title={node.frontmatter.title}
                                id={node.id}
                                slug={node.fields.slug}
                                categories={node.frontmatter.categories}
                                image={node.frontmatter.image}
                                date={node.frontmatter.date}
                                readingTime={node.fields.readingTime.text}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

BlogArea.defaultProps = {
    id: "blog",
};

export default BlogArea;
