import * as React from "react";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Layout from "@layout";
import Header from "@layout/@danny/header";
import Footer from "@layout/footer/layout-01";
import BlogArea from "@containers/@danny/article";

const BlogListPage = ({ data, pageContext: { prev, next } }) => {
    const content = normalizedData(data?.homePage?.content || []);

    return (
        <Layout pageTitle="Blog">
            <Header
                data={{
                    ...data.header,
                    ...data.navigation,
                    socials: data.site.siteMetadata.socials,
                }}
            />
            <main className="main-page-wrapper">
                <BlogArea
                    data={{
                        ...content["blog-section"],
                        blogs: data?.allMarkdownRemark?.edges,
                    }}
                />
                {/* <ul>
                    {Array.from({ length: pageContext.numPages }).map(
                        (item, i) => {
                            const index = i + 1;
                            const link =
                                index === 1 ? "/blog" : `/blog/page/${index}`;
                            return (
                                <li key={i}>
                                    {pageContext.currentPage === index ? (
                                        <span>{index}</span>
                                    ) : (
                                        <a href={link}>{index}</a>
                                    )}
                                </li>
                            );
                        }
                    )}
                </ul> */}
            </main>
            <Footer data={{ ...data.footer }} className="section-separator" />
        </Layout>
    );
};

export const query = graphql`
    query BlogList($skip: Int, $limit: Int) {
        site {
            ...Site
        }
        header: general(section: { eq: "header-1" }) {
            ...Header01
        }
        navigation: general(section: { eq: "menu-1" }) {
            menu {
                ...Menu01
            }
        }
        footer: general(section: { eq: "footer-1" }) {
            ...Footer01
        }
        homePage(title: { eq: "blog-home" }) {
            content {
                ...Content01
            }
        }
        allMarkdownRemark(
            sort: { fields: [frontmatter___date], order: DESC }
            limit: $limit
            skip: $skip
        ) {
            edges {
                node {
                    id
                    fields {
                        slug
                        readingTime {
                            text
                        }
                    }
                    frontmatter {
                        title
                        date
                        author
                        categories
                        image {
                            childImageSharp {
                                gatsbyImageData(
                                    quality: 100
                                    formats: WEBP
                                    placeholder: TRACED_SVG
                                    outputPixelDensities: [0.25, 0.5, 1, 2]
                                )
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default BlogListPage;
